/* Custom Utilities */

.text-navy        {  color: @ether-navy;    }
.text-gray-lighter    {  color: @gray-lighter;    }
.text-blue        {  color: @ether-blue;    }
.text-white       {  color: #fff;           }

.bg-navy { background: @ether-navy; }
.bg-gradient {
  background: @ether-navy;
  background: linear-gradient(149deg,#132a45,#143a56,#21a4ce,#19b4ad);
}
.bg-gray-lighter    {  background-color: @gray-lighter;  }
.bg-blue        {  background-color: @ether-blue;  }
.bg-white       {  background-color: #fff;         }

.text-serif       {  font-family: @font-family-serif; }
.text-sans-serif  {  font-family: @font-family-sans-serif; }

.pad-v-sm {
  padding-top: .5em;
  padding-bottom: .5em;
}

.pad-v-md {
  padding-top: 1em;
  padding-bottom: 1em;
}

.pad-v-lg {
  padding-top: 1.5em;
  padding-bottom: 1.5em;
}
.pad-v-xl {
  padding-top: 2em;
  padding-bottom: 2em;
}

.marg-v-sm {
  margin-top: .5em;
  margin-bottom: .5em;
}

.marg-v-md {
  margin-top: 1em;
  margin-bottom: 1em;
}

.marg-v-lg {
  margin-top: 1.5em;
  margin-bottom: 1.5em;
}
.marg-v-xl {
  margin-top: 2em;
  margin-bottom: 2em;
}

.img-fill {
  width: 100%;
  height: auto;
  max-width: 100%;
}
