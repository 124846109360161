// Header
.header-branding {
  color: white;
  padding: 0;
  @media screen and (max-width: 900px) {
    text-align: center;
  }
  .container {
    display: flex;
    align-items: center;
    @media screen and (max-width: 900px) {
      flex-direction: column;
    }
  }
  .brand {
    @media screen and (min-width: 901px) {
      flex-basis: 245px;
    }
    img {
      max-width: 1000px;
      padding: 5px 0;
    }
  }
  .tagline {
    font-size: 18px;
    font-weight: 200;
    color: white;
    flex: 1 auto;
    text-align: right;
    padding: 5px 0;
    @media screen and (max-width: 900px) {
      text-align: center;
    }
    > * {
      display: inline;
      vertical-align: middle;
    }
  }
  a {
    color: white;
    cursor: pointer;
    font-weight: 400;
    transition: 250ms all ease;
  }
  a:hover,
  a:active,
  a:focus {
    opacity: .8;
    color: white;
    text-decoration: none;
    transition: 250ms all ease;
  }
  .dropdown {
    padding: 0;
    text-align: right;
    .dropdown-menu {
      right: -10px;
      left: auto;
      min-width: auto;
      left: auto;
      & > li > a {
        font-size: 15px;
        padding: 5px 30px 5px 15px;
        position: relative;
      }
    }
  }
}

.dropdown-menu a.active {
  text-decoration: none;
  color: @brand-primary;
  background-color: @gray-lightest;
}

// Navigation
.nav-container {
  position: relative;
  overflow-y: hidden;

  .nav-scroll {
    -ms-overflow-style: -ms-autohiding-scrollbar;
    -webkit-overflow-scrolling: touch;
    overflow-x: auto;
    margin-bottom: -20px;
    font-size: 0;
    white-space: nowrap;
    @media screen and (max-width: @screen-sm) {
      margin: 0 -5% -20px;
    }
    .nav-inner {
      border-bottom: 2px solid @gray-lighter;
      display: inline-block;
      font-size: 0;
      margin-bottom: 20px;
      min-width: 100%;
      padding-top: 5px;
      vertical-align: middle;
      .nav-item {
        display: inline-block;
        font-size: 0;
        a {
          color: darken(@link-color, 15%);
          display: block;
          font-size: 16px;
          font-weight: 300;
          padding: 10px;
          white-space: nowrap;
          position: relative;
        }
        a:after {
          content: "";
          background: @brand-primary;
          height: 2px;
          width: 100%; left: 0px;
          position: absolute;
          bottom: -1px;
          transition: all 250ms ease 0s;
          transform: scale(0);
        }
        &.active a,
        a:hover,
        a:focus {
          color: @brand-primary;
          text-decoration: none;
          transition: all 250ms ease 0s;
          &:after {
            transform: scale(1);
            transition: all 250ms ease 0s;
          }
        }
      }
    }
  }

  .nav-arrow-right,
  .nav-arrow-left {
    background-color: white;
    bottom: 12px;
    color: #d6d6d6;
    font-size: 33px;
    line-height: 1.3;
    min-width: 50px;
    position: absolute;
    top: 0;
    vertical-align: middle;
    width: 5%;
    z-index: 999;
    &:hover {
      text-decoration: none;
    }
  }
  .nav-arrow-right {
    right: 3%;
    background: linear-gradient(to right, rgba(255,255,255,0) 0%,rgba(255,255,255,1) 47%,rgba(255,255,255,1) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
    padding-right: 5px;
    text-align: right;
    @media screen and (max-width: @screen-sm) {
      right: 0;
    }
  }
  .nav-arrow-left {
    left: 3%;
    background: linear-gradient(to left, rgba(255,255,255,0) 0%,rgba(255,255,255,1) 47%,rgba(255,255,255,1) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
    padding-left: 5px;
    text-align: left;
    @media screen and (max-width: @screen-sm) {
      left: 0;
    }
  }
}


