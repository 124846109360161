@font-face {
  font-family: 'Lato';
  src: url('../fonts/Lato-Light.woff') format('woff'),
       url('../fonts/Lato-Light.ttf')  format('truetype');
  font-style:   normal;
  font-weight:  300;
}

@font-face {
  font-family: 'Lato';
  src:  url('../fonts/Lato-Regular.woff') format('woff'),
       url('../fonts/Lato-Regular.ttf')  format('truetype');
  font-style:   normal;
  font-weight:  400;
}

@font-face {
  font-family: 'Lato';
  src: url('../fonts/Lato-Bold.woff') format('woff'),
       url('../fonts/Lato-Bold.ttf')  format('truetype');

  font-style:   normal;
  font-weight:  700;
}
